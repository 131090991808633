import React, { useState, useEffect, useRef } from "react";
import styles from "./slider.module.css";

function formatToIndianCurrency(amount) {
  return new Intl.NumberFormat("en-IN", {
    currency: "INR",
  }).format(amount);
}

const Slider = ({
  startRange,
  endRange,
  percentage,
  year,
  price,
  step,
  onChange,
  value: initialValue, // Accept initial value as a prop
}) => {
  const [value, setValue] = useState(initialValue); // Initialize with the provided value
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const sliderRef = useRef(null);
  const tooltipRef = useRef(null);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    onChange(newValue);
  };

  const handleMouseDown = () => {
    setIsTooltipVisible(true);
  };

  const handleMouseUp = () => {
    setIsTooltipVisible(false);
  };

  useEffect(() => {
    if (sliderRef.current && tooltipRef.current) {
      const progress = ((value - startRange) / (endRange - startRange)) * 100;
      sliderRef.current.style.setProperty("--progress", `${progress}%`);

      if (isTooltipVisible) {
        const thumbWidth = sliderRef.current.offsetWidth;
        const tooltipWidth = tooltipRef.current.offsetWidth;
        const position = (progress / 100) * thumbWidth - tooltipWidth / 2 + 30;
        tooltipRef.current.style.left = `${position}px`;
      }
    }
  }, [value, startRange, endRange, isTooltipVisible]);

  return (
    <div
      className={styles.SliderContainer}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onTouchStart={handleMouseDown}
      onTouchEnd={handleMouseUp}
    >
      <input
        ref={sliderRef}
        className={`${styles.slider}-${startRange}`}
        type="range"
        min={startRange}
        max={endRange}
        value={value} // Ensure the slider reflects the current value
        onChange={handleChange}
        step={step}
      />
      {isTooltipVisible && (
        <div ref={tooltipRef} className={styles.tooltip}>
          {value}
        </div>
      )}
      <p className={styles.pricingslidersection}>
        <span className={styles.sliderspan}>
          {price && "₹"} {formatToIndianCurrency(startRange)}{" "}
          {percentage && "%"} {year && "year"}
        </span>
        <span className={styles.sliderspan}>
          {price && "₹"} {formatToIndianCurrency(endRange)} {percentage && "%"}{" "}
          {year && "years"}
        </span>
      </p>
    </div>
  );
};

export default Slider;
