import React, { useState } from "react";
import styles from "./features.module.css";
import logo from "../../../../assets/Marketing_landing_Pages/Features/logo.svg";

const Features = () => {
  const [ppf, setPpf] = useState(false);
  return (
    <div className={styles.featureContent}>
      <div className={styles.container}>
        <h1>
          Here’s why NPS is becoming a popular investment tool for retirement
          planning
        </h1>
      </div>

      <div className={styles.featuresContent}>
        <div>
          <h1>List of features</h1>
          <p>Let’s see which one gets you what</p>

          <ul>
            <li>Min expected returns</li>
            <li>Long term returns</li>
            <li>Risk</li>
            <li>Access to funds</li>
            <li>Tax efficiency</li>
          </ul>
        </div>

        <div>
          <div>
            <h1>NPS on Fabits</h1>

            <div>
              <p>In partnership with</p>
              <img src={logo} alt="icici logo" />
            </div>
          </div>

          <ul>
            <li>10 - 14%</li>
            <li>Potentially higher</li>
            <li>Low</li>
            <li>Immediate</li>
            <li>Moderate</li>
          </ul>

          <div>
            <button>
              <span>Plan with Fabits</span>
            </button>
          </div>
        </div>

        <div></div>

        <div>
          <h1>EPF</h1>

          <ul>
            <li>8-9%</li>
            <li>Safe Moderate Returns</li>
            <li>Low</li>
            <li>Immediate</li>
            <li>Moderate</li>
          </ul>
        </div>

        <div>
          <h1>PPF</h1>

          <ul>
            <li>7-8%</li>
            <li>High (8-12%)</li>
            <li>High</li>
            <li>varies</li>
            <li>Low</li>
          </ul>
        </div>
      </div>

      <div className={styles.featuresContent_tab}>
        <div>
          <div className={styles.heading}>
            <h1>NPS on Fabits</h1>
            <div>
              <p>in partnership with</p>
              <img src={logo} alt="icici logo" />
            </div>
          </div>
          <div className={styles.feature}>
            <div>
              <div>
                <div>
                  <span class="material-symbols-rounded">timeline</span>
                </div>
                <div>
                  <p>Returns</p>
                  <p>Moderate</p>
                </div>
              </div>
              <p>10-14%</p>
            </div>
            <div>
              <div>
                <div>
                  <span class="material-symbols-rounded">water_drop</span>
                </div>
                <div>
                  <p>Liquidity</p>
                  <p>High</p>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>
                  <span class="material-symbols-rounded">
                    local_fire_department
                  </span>
                </div>
                <div>
                  <p>Risk</p>
                  <p>Low</p>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>
                  <span class="material-symbols-rounded">assured_workload</span>
                </div>
                <div>
                  <p>Tax efficiency</p>
                  <p>Moderate</p>
                </div>
              </div>
              <p>Capital gains tax</p>
            </div>
            <div>
              <div>
                <div>
                  <span class="material-symbols-rounded">
                    volunteer_activism
                  </span>
                </div>
                <div>
                  <p>Access to funds</p>
                  <p>Immediate</p>
                </div>
              </div>
              <p>Via stock market</p>
            </div>
          </div>
          <div className={styles.nextBtn} onClick={() => setPpf(!ppf)}>
            <p>Plan with Fabits</p>
            <span class="material-symbols-rounded">arrow_right_alt</span>
          </div>
        </div>

        <div>
          <div>
            <div>EPF</div>

            <div className={styles.featureEPF}>
              <div>
                {/* <div>
                  <span class="material-symbols-rounded">
                    do_not_disturb_on
                  </span>
                </div> */}
                <div>
                  <p>Moderate</p>
                  {/* <p>Immediate</p> */}
                </div>
              </div>
              {/* <p>Via stock market</p> */}
            </div>
            <div className={styles.featureEPF}>
              <div>
                {/* <div>
                  <span class="material-symbols-rounded">
                    volunteer_activism
                  </span>
                </div> */}
                <div>
                  <p>Low</p>
                </div>
              </div>
            </div>
            <div className={styles.featureEPF}>
              <div>
                {/* <div>
                  <span class="material-symbols-rounded">
                    volunteer_activism
                  </span>
                </div> */}
                <div>
                  <p>Low</p>
                </div>
              </div>
            </div>
            <div className={styles.featureEPF}>
              <div>
                {/* <div>
                  <span class="material-symbols-rounded">
                    volunteer_activism
                  </span>
                </div> */}
                <div>
                  <p>Moderate</p>
                </div>
              </div>
            </div>
            <div className={styles.featureEPF}>
              <div>
                {/* <div>
                  <span class="material-symbols-rounded">
                    volunteer_activism
                  </span>
                </div> */}
                <div>
                  <p>Immediate</p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div>PPF</div>

            <div className={styles.featureEPF}>
              <div>
                {/* <div>
                  <span class="material-symbols-rounded">
                    volunteer_activism
                  </span>
                </div> */}
                <div>
                  <p>High</p>
                </div>
              </div>
            </div>
            <div className={styles.featureEPF}>
              <div>
                {/* <div>
                  <span class="material-symbols-rounded">
                    volunteer_activism
                  </span>
                </div> */}
                <div>
                  <p>Low</p>
                </div>
              </div>
            </div>
            <div className={styles.featureEPF}>
              <div>
                {/* <div>
                  <span class="material-symbols-rounded">
                    volunteer_activism
                  </span>
                </div> */}
                <div>
                  <p>High</p>
                </div>
              </div>
            </div>
            <div className={styles.featureEPF}>
              <div>
                {/* <div>
                  <span class="material-symbols-rounded">
                    volunteer_activism
                  </span>
                </div> */}
                <div>
                  <p>Low</p>
                </div>
              </div>
            </div>
            <div className={styles.featureEPF}>
              <div>
                {/* <div>
                  <span class="material-symbols-rounded">
                    volunteer_activism
                  </span>
                </div> */}
                <div>
                  <p>Varies</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.featuresContent_mobile}>
        <div>
          <div className={styles.heading}>
            <h1>NPS on Fabits</h1>
            <div>
              <p>in partnership with</p>
              <img src={logo} alt="icici logo" />
            </div>
          </div>
          <div className={styles.feature}>
            <div>
              <div>
                <div>
                  <span class="material-symbols-rounded">timeline</span>
                </div>
                <div>
                  <p>Returns</p>
                  <p>Moderate</p>
                </div>
              </div>
              <p>10-14%</p>
            </div>
            <div>
              <div>
                <div>
                  <span class="material-symbols-rounded">water_drop</span>
                </div>
                <div>
                  <p>Liquidity</p>
                  <p>High</p>
                </div>
              </div>
            </div>

            <div>
              <div>
                <div>
                  <span class="material-symbols-rounded">
                    local_fire_department
                  </span>
                </div>
                <div>
                  <p>Risk</p>
                  <p>Low</p>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>
                  <span class="material-symbols-rounded">assured_workload</span>
                </div>
                <div>
                  <p>Tax efficiency</p>
                  <p>Moderate</p>
                </div>
              </div>
              <p>Capital gains tax</p>
            </div>
            <div>
              <div>
                <div>
                  <span class="material-symbols-rounded">
                    volunteer_activism
                  </span>
                </div>
                <div>
                  <p>Access to funds</p>
                  <p>Immediate</p>
                </div>
              </div>
              <p>Via stock market</p>
            </div>
          </div>

          {/* <div className={styles.planBtn}>
            <button>
              <span>Plan with fabits</span>
            </button>
          </div> */}
          <div className={styles.nextBtn}>
            <p>Plan with Fabits</p>
            <span class="material-symbols-rounded">arrow_right_alt</span>
          </div>
        </div>

        <div>
          {!ppf ? (
            <div>
              <div>PPF</div>

              <div className={styles.featureEPF}>
                <div>
                  {/* <div>
                  <span class="material-symbols-rounded">
                    volunteer_activism
                  </span>
                </div> */}
                  <div>
                    <p>High</p>
                  </div>
                </div>
              </div>
              <div className={styles.featureEPF}>
                <div>
                  {/* <div>
                    <span class="material-symbols-rounded">
                      volunteer_activism
                    </span>
                  </div> */}
                  <div>
                    <p>Low</p>
                  </div>
                </div>
              </div>
              <div className={styles.featureEPF}>
                <div>
                  {/* <div>
                  <span class="material-symbols-rounded">
                    volunteer_activism
                  </span>
                </div> */}
                  <div>
                    <p>High</p>
                  </div>
                </div>
              </div>
              <div className={styles.featureEPF}>
                <div>
                  {/* <div>
                  <span class="material-symbols-rounded">
                    volunteer_activism
                  </span>
                </div> */}
                  <div>
                    <p>Low</p>
                  </div>
                </div>
              </div>
              <div className={styles.featureEPF}>
                <div>
                  {/* <div>
                  <span class="material-symbols-rounded">
                    volunteer_activism
                  </span>
                </div> */}
                  <div>
                    <p>Varies</p>
                  </div>
                </div>
              </div>

              <div
                className={`${styles.nextCardBtn} ${styles.left}`}
                onClick={() => setPpf(!ppf)}
              >
                <span class="material-symbols-rounded">arrow_back_ios</span>
                {/* <p>Previous</p> */}
              </div>

              <div
                className={`${styles.nextCardBtn} ${styles.right}`}
                onClick={() => setPpf(!ppf)}
              >
                {/* <p>Next</p> */}
                <span class="material-symbols-rounded">arrow_forward_ios</span>
              </div>
            </div>
          ) : (
            <div>
              <div>EPF</div>

              <div className={styles.featureEPF}>
                <div>
                  {/* <div>
                    <span class="material-symbols-rounded">
                      do_not_disturb_on
                    </span>
                  </div> */}
                  <div>
                    <p>Moderate</p>
                    {/* <p>Immediate</p> */}
                  </div>
                </div>
                {/* <p>Via stock market</p> */}
              </div>
              <div className={styles.featureEPF}>
                <div>
                  {/* <div>
                    <span class="material-symbols-rounded">
                      volunteer_activism
                    </span>
                  </div> */}
                  <div>
                    <p>Low</p>
                  </div>
                </div>
              </div>
              <div className={styles.featureEPF}>
                <div>
                  {/* <div>
                  <span class="material-symbols-rounded">
                    volunteer_activism
                  </span>
                </div> */}
                  <div>
                    <p>Low</p>
                  </div>
                </div>
              </div>
              <div className={styles.featureEPF}>
                <div>
                  {/* <div>
                  <span class="material-symbols-rounded">
                    volunteer_activism
                  </span>
                </div> */}
                  <div>
                    <p>Moderate</p>
                  </div>
                </div>
              </div>
              <div className={styles.featureEPF}>
                <div>
                  {/* <div>
                  <span class="material-symbols-rounded">
                    volunteer_activism
                  </span>
                </div> */}
                  <div>
                    <p>Immediate</p>
                  </div>
                </div>
              </div>

              <div
                className={`${styles.nextCardBtn} ${styles.left}`}
                onClick={() => setPpf(!ppf)}
              >
                <span class="material-symbols-rounded">arrow_back_ios</span>
                {/* <p>Previous</p> */}
              </div>

              <div
                className={`${styles.nextCardBtn} ${styles.right}`}
                onClick={() => setPpf(!ppf)}
              >
                {/* <p>Next</p> */}
                <span class="material-symbols-rounded">arrow_forward_ios</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Features;
