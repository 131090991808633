import React, { useState, useRef, useEffect } from "react";
import styles from "./calculator.module.css";
import Slider from "./Slider/Slider";
import Tooltip from "./Tooltip/Tooltip";
import Ttip from "./Ttip/Ttip";

function formatToIndianCurrency(amount) {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits: 0, // Ensure no decimal places are shown
  }).format(amount);
}

const Calculator = () => {
  const [age, setAge] = useState(22);
  const [investment, setInvestment] = useState(20000);
  const [corpus, setCorpus] = useState(null);

  const investedFor = 60 - age;

  function calculateNPSCorpusAndDetails(
    durationYears,
    initialSIP,
    sipGrowthRate,
    annualReturnRate
  ) {
    const months = durationYears * 12;
    const monthlyReturnRate = annualReturnRate / 100 / 12; // Convert annual return rate to a monthly return rate
    let totalCorpus = 0;
    let totalInvested = 0;

    for (let i = 0; i < months; i++) {
      // Calculate the current SIP amount considering the annual step-up
      const currentSIP =
        initialSIP * Math.pow(1 + sipGrowthRate / 100, Math.floor(i / 12));

      // Calculate the future value of the SIP contribution for the remaining months
      const futureValue =
        currentSIP * Math.pow(1 + monthlyReturnRate, months - i);

      // Add the SIP amount to the total invested amount
      totalInvested += currentSIP;

      // Add the future value to the total corpus
      totalCorpus += futureValue;
    }

    const interestEarned = totalCorpus - totalInvested;

    setCorpus({
      totalCorpus: Math.round(totalCorpus),
      totalInvested: Math.round(totalInvested),
      interestEarned: Math.round(interestEarned),
    });

    return {
      totalCorpus: Math.round(totalCorpus),
      totalInvested: Math.round(totalInvested),
      interestEarned: Math.round(interestEarned),
    };
  }

  useEffect(() => {
    calculateNPSCorpusAndDetails(investedFor, investment, 10, 12);
  }, [investedFor, investment]);

  const investedPercentage =
    (corpus?.totalInvested / corpus?.totalCorpus) * 100;
  const interestPercentage =
    (corpus?.interestEarned / corpus?.totalCorpus) * 100;

  const [isInvestedTooltipVisible, setIsInvestedTooltipVisible] =
    useState(false);
  const [isInterestTooltipVisible, setIsInterestTooltipVisible] =
    useState(false);

  const investedDivRef = useRef(null);
  const interestDivRef = useRef(null);
  const [investedTooltipPosition, setInvestedTooltipPosition] = useState(0);
  const [interestTooltipPosition, setInterestTooltipPosition] = useState(0);

  const content = (
    <div className={styles.tooltipContent}>
      <div>
        <div>
          <div className={styles.legend_1}></div>
          <p>Monthly Investment</p>
        </div>
        <div>{formatToIndianCurrency(corpus?.totalInvested)}</div>
      </div>
      <div>
        <div>
          <div className={styles.legend_2}></div>
          <p>Interest Earned</p>
        </div>
        <div>{formatToIndianCurrency(corpus?.interestEarned)}</div>
      </div>
    </div>
  );

  useEffect(() => {
    if (investedDivRef.current && isInvestedTooltipVisible) {
      const divWidth = investedDivRef.current.offsetWidth;
      const position = divWidth / 2 - 20; // Center the tooltip
      setInvestedTooltipPosition(position);
    }
  }, [isInvestedTooltipVisible]);

  useEffect(() => {
    if (interestDivRef.current && isInterestTooltipVisible) {
      const divWidth = interestDivRef.current.offsetWidth;
      const position = divWidth / 2 - 20; // Center the tooltip
      setInterestTooltipPosition(position);
    }
  }, [isInterestTooltipVisible]);

  return (
    <div className={styles.contentContainer}>
      <h1>Here’s how your savings grow by the time you retire</h1>

      <div className={styles.calcContainer}>
        <div>
          <div>
            <p>Current Age</p>
            <div>
              <Slider
                endRange={60}
                startRange={18}
                year
                step={1}
                onChange={setAge}
                value={age} // Pass current age value
              />
            </div>
          </div>

          <div>
            <p>Monthly Investment</p>
            <div>
              <Slider
                endRange={200000}
                startRange={1000}
                price
                step={1000}
                onChange={setInvestment}
                value={investment} // Pass the initial value (10000)
              />
            </div>
          </div>

          {/* <div>
            <p>Step Up</p>
            <div>
              <Slider
                endRange={20}
                startRange={1}
                percentage
                step={1}
                onChange={setStepUp}
                value={stepup} // Pass the initial value (10000)
              />
            </div>
          </div> */}
        </div>

        <div className={styles.graphContainer}>
          <div>
            <div
              ref={investedDivRef}
              style={{
                width: `${investedPercentage}%`,
              }}
              onMouseEnter={() => setIsInvestedTooltipVisible(true)}
              onMouseLeave={() => setIsInvestedTooltipVisible(false)}
            >
              <Tooltip
                isVisible={isInvestedTooltipVisible}
                position={investedTooltipPosition}
                value={investedPercentage.toFixed(2)}
              />
            </div>
            <div
              ref={interestDivRef}
              style={{
                width: `${interestPercentage}%`,
              }}
              onMouseEnter={() => setIsInterestTooltipVisible(true)}
              onMouseLeave={() => setIsInterestTooltipVisible(false)}
            >
              <Tooltip
                isVisible={isInterestTooltipVisible}
                position={interestTooltipPosition}
                value={interestPercentage.toFixed(2)}
              />
            </div>
          </div>

          <div>
            <div>
              <div>
                <p>Pension Wealth</p>
                <Ttip content={content}>
                  <span class="material-symbols-rounded">arrow_drop_down</span>
                </Ttip>
              </div>
            </div>
            <div>{formatToIndianCurrency(Math.round(corpus?.totalCorpus))}</div>
          </div>

          <div>
            <div>
              <p>Monthly Pension</p>
              {/* <Ttip content={"Assuming 6% pension returns"}>
                <span
                  class="material-symbols-rounded"
                  style={{ fontSize: "12px" }}
                >
                  emergency
                </span>
              </Ttip> */}
            </div>
            <div>
              {formatToIndianCurrency(Math.round(corpus?.totalCorpus / 200))}
            </div>
          </div>
        </div>
      </div>

      <div>
        <ul>
          <li>Assuming an annual increase of 10% on monthly investment</li>
          <li>Assuming 6% pension returns</li>
        </ul>
      </div>
    </div>
  );
};

export default Calculator;
