import React from "react";
import styles from "./talkToUs.module.css";

const TalkToUs = () => {
  return (
    <div className={styles.container}>
      <h1>Build a smart retirement plan</h1>
      <p>Talk to us to build a plan that’s most suitable for you</p>

      <div className={styles.TalkToUsBTn}>
        <button><span>Talk to us</span></button>
      </div>
    </div>
  );
};

export default TalkToUs;
