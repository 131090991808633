import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import styles from "./heroCarousel.module.css";

const HeroCarousel = ({ items }) => {
  return (
    <Swiper
      spaceBetween={0}
      slidesPerView={1}
      loop={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      modules={[Autoplay]}
      className={styles.carouselMain}
      style={{ width: "618px" }}
    >
      {items.map((item) => {
        return (
          <SwiperSlide
            style={{ width: "618px" }}
            key={item.id}
            className={styles.carousel}
          >
            <span className={styles.innerContent}>{item.title}</span>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default HeroCarousel;
