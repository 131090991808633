import React from "react";
import styles from "./nps.module.css";
import Calculator from "./Calculator/Calculator";
import FAQ from "./FAQ/FAQ";
import GetInTouch from "./GetInTouch/getInTouch";
import WhyNps from "./WhyNps/WhyNps";
import TalkToUs from "./TalkToUs/TalkToUs";
import HowNpsWorks from "./How/HowNpsWorks";
import AboutUs from "./AboutUs/AboutUs";
import WhatIsNps from "./WhatIsNps/WhatIsNps";
import Features from "./Features/Features";
import HeroSection from "./HeroSection/HeroSection";

const NpsLp = () => {
  return (
    <div className={styles.container}>
      <HeroSection />
      <div className={styles.content}>
        <WhatIsNps />
        <HowNpsWorks />
        <Calculator />
        <WhyNps />
        <TalkToUs />
        <Features />
        <AboutUs />
        <FAQ />
      </div>
      <div className={styles.getInTouch}>
        <GetInTouch />
      </div>
    </div>
  );
};

export default NpsLp;
