import React from "react";
import styles from "./hownpsworks.module.css";
import howImage from "../../../../assets/Marketing_landing_Pages/HowNpsWorks/How NPS works final.svg";

const HowNpsWorks = () => {
  return (
    <div className={styles.container}>
      <h1>How does NPS work?</h1>
      <p>Here’s how NPS helps plan your retirement</p>
      <div>
        <img src={howImage} alt="how NPS works"/>
      </div>
    </div>
  );
};

export default HowNpsWorks;
