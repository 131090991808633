import React from "react";
import styles from "./getInTouch.module.css";

const GetInTouch = () => {
  return (
    <div className={styles.container}>
      <h1>Confused which plan to pick? </h1>
      <p>Get in touch with us for complete, dedicated assistance</p>
      <div className={styles.btn}>
        <button>
          <span>Get in touch</span>
        </button>
      </div>
    </div>
  );
};

export default GetInTouch;
