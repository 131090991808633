import React from "react";
import styles from "./aboutUs.module.css";

const AboutUs = () => {
  return (
    <div className={styles.container}>
      <div>
        <div className={styles.heading}>
          <h1>What is Fabits?</h1>
          <p>
            Fabits is a wealth-tech platform on a mission to make financial
            freedom & wellness accessible to all. F&O is our brand new offering
            for customers who seek high risk & high rewards.
          </p>

          <p>
            Get a personal (finance) assistant on your fingertips; sign up on
            Fabits now!
          </p>
        </div>
        <div>
          <iframe
            className={styles.iframe}
            width="560"
            height="315"
            src="https://www.youtube.com/embed/70DiXsiG5Tw?si=0ZHsWBhdOcDUHUl0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>

      <div className={styles.rightContent}>
        <div>
          <div>
            <img src="/TaxFiling/men.svg" alt="Fabits" />
          </div>
          <div>
            <h3>Decades of Wealth Management</h3>
            <p>
              25+ years of experience in planning & managing wealth for the
              ultra rich
            </p>
          </div>
        </div>
        <div>
          <div>
            <img src="/TaxFiling/women.svg" alt="Fabits" />
          </div>
          <div>
            <h3>Guiding Your Wealth</h3>
            <p className="sub-heading">
              Constant support & hand holding, because money defines the way you
              live life
            </p>
          </div>
        </div>
        <div>
          <div>
            <img src="/TaxFiling/desk.svg" alt="Fabits" />
          </div>
          <div>
            <h3>Tech-Driven Financial Planning</h3>
            <p>
              Humane financial planning with experts, assisted by deep tech
              models for accuracy
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
