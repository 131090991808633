import React from "react";
import styles from "./FAQ.module.css";
import Accordian from "./Accordian/Accordian";

const faq = [
  {
    id: 1,
    question: "Are my investments with Fabits protected?",
    answer: (
      <p>
        While technology has consumed every facet of our lives, we believe that
        it can’t truly replace the human connect. For any support and
        information regarding your portfolio you can reach out to us 7 days a
        week by scheduling a 1-1 session at{" "}
        <a href="https://fabits.com/support">https://fabits.com/support</a>
      </p>
    ),
  },
  {
    id: 2,
    question:
      "Does Fabits charge me a fee for taking money and investments out of my account?",
    answer: (
      <p>
        No. We don’t charge anything for withdrawals, transferring out, or
        leaving your account open with a zero balance.
      </p>
    ),
  },
  {
    id: 3,
    question: "How does Fabits work?",
    answer: (
      <>
        <p>
          We are a goal based investing platform. We help manage your money
          better by helping you define your goals, create an action plan to
          reach your goals and handhold you till you get there. We are a
          fiduciary, which means we act in your best interest.
        </p>
        <br />
        <p>
          We’ll ask a bit about you when you sign up. Then, we’ll help you set
          financial goals and set you up with smart investment boxes (SIBs) for
          each goal. SIBs comprise of assets that are broadly diversified across
          markets, are expected to perform well over the long-term, keep fees
          low, and don’t try to time the market. For most people, we believe
          this is the best way to invest in public stock and bond markets. Each
          goal needs to be treated differently and risk taken varies. Our
          education portfolio is more conservative than a car purchase portfolio
          because education is critical in terms of time. In the event of great
          volatility such as what we have seen during COVID, we can delay the
          purchase of your car but can’t delay the education for your daughter.
        </p>
        <br />
        <p>
          We also track your progress with our AI-tech driven Advanced Investor
          Assist System (AIAS) and ensure the investments are changed based on
          changing market conditions. This ensures, you get better risk-adjusted
          returns in the long run.
        </p>
        <br />
        <p>
          The most important determinant of wealth for investors is their
          savings rate and their ability to stick with an investment strategy.
          That’s why we provide a technology experience that is designed to help
          you invest long-term and stick to your plan.
        </p>
      </>
    ),
  },
  {
    id: 4,
    question: "What assets does Fabits invest in?",
    answer: (
      <>
        <p>
          To help you on your journey to reach your financial goal while taking
          into consideration your risk appetite, time period, etc, Fabits makes
          the use of a very versatile item known as ETFs.
        </p>
        <br />
        <p>
          ETFs (Exchange Traded Funds) are a cost-effective and efficient
          investment option that combines the diversification benefits of mutual
          funds with the flexibility of stocks. They are a type of investment
          security that tracks a reference index and can be bought or sold like
          an individual stock, offering diversification and flexibility.
        </p>
        <br />
        <p>
          ETFs have lower costs (TER, ie, Total Expense Ratio) compared to
          mutual funds. They don’t pay commissions, unlike mutual funds. Which
          means we do not get any hidden commission when we offer guidance, so
          rest assured, we act in your best interest.
        </p>
        <br />
        <p>
          Additional benefits of ETFs include no exit or entry loads, no lock-in
          periods, and no commissions, making them a preferred choice for
          cost-effective and high-performing investments. Most ETFs are
          passively managed, tracking reference indices like the Nifty 50,
          providing returns similar to the index over time.
        </p>
      </>
    ),
  },
  {
    id: 5,
    question: "What are Fabits fees?",
    answer: (
      <>
        <p>
          While most entities in the industry charge fees based on percentage of
          the amount invested, Fabits believe in the power of a structured fees
          system. This helps you in 2 ways:
        </p>
        <br />
        <ul>
          <li>
            Get unbiased advice as we don’t earn based on the amount you invest
            and trade.
          </li>
          <li>
            Save on commission paid while only paying a fixed fees* every month.
          </li>
        </ul>
        <br />
        <p>
          Fabits is committed to ensuring everyone has access to better
          financial planning and investing with expert access. Our fee structure
          is transparent and we earn no hidden commissions.
          <a href="https://www.youtube.com/shorts/WiyVGiPvtOE?feature=share">
            Watch this video on how a fee based structure can help you
          </a>
        </p>
        <br />
        <p>
          For more details, visit:{" "}
          <a href="https://fabits.com/pricing">https://fabits.com/pricing</a>
        </p>
        <br />
        <p>
          <i>*Taxes and other regulatory charges are applicable.</i>
        </p>
      </>
    ),
  },
];

const FAQ = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>Frequently Asked Questions</h1>

        <div className={styles.faqContainer}>
          {faq.map((item) => (
            <>
              <Accordian
                key={item.id}
                title={item.question}
                desc={item.answer}
              />
              <hr className={styles.ruler} />
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
