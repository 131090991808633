import React from "react";
import styles from "./whynps.module.css";


const cards = [
  {
    id: 1,
    image: <span class="material-symbols-rounded">handshake</span>,
    content: (
      <>
        It is <span>regulated by the PFRDA</span>, the regulatory body for
        retirement and pension-related schemes in India.
      </>
    ),
  },
  {
    id: 2,
    image: <span class="material-symbols-rounded">cognition</span>,
    content: (
      <>
        <span>Voluntary</span> contribution according to convenience; no
        dependency on employer
      </>
    ),
  },
  {
    id: 3,
    image: <span class="material-symbols-rounded">ads_click</span>,
    content: (
      <>
        NPS is <span>suitable for all ages</span>. It is designed to cater from
        age 18 up to when you’re 70 years old
      </>
    ),
  },
  {
    id: 4,
    image: <span class="material-symbols-rounded">support_agent</span>,
    content: (
      <>
        NPS is <span>safe, trustworthy and secure</span>. It is backed by the
        Indian government, and regulated centrally
      </>
    ),
  },
];

const WhyNps = () => {
  return (
    <div className={styles.container}>
      <h1>Why invest in NPS?</h1>
      <p>
        Here’s why choosing to open an NPS account with Fabits, in partnership
        with ICICI, can bring a load of benefits!
      </p>
      <div className={styles.cardContainer}>
        {cards.map((card) => {
          return (
            <div key={card.id}>
              <div>
                {card.image}
                {/* <img src={card.image} alt={card.id} /> */}
              </div>
              <div>{card.content}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WhyNps;
