import React from "react";
import styles from "./tooltip.module.css";

const Tooltip = ({ isVisible, position, value }) => {
  return (
    isVisible && (
      <div className={styles.tooltip} style={{ left: `${position}px` }}>
        {value}%
      </div>
    )
  );
};

export default Tooltip;
