import React, { useState } from "react";
import styles from './Ttip.module.css'

const Ttip = ({ children, content }) => {
  const [isVisible, setIsVisible] = useState(false);

  const showTooltip = () => {
    setIsVisible(true);
  };

  const hideTooltip = () => {
    setIsVisible(false);
  };
  return (
    <div
      className={styles["tooltip-container"]}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {children}
      {isVisible && <div className={styles["tooltip-content"]}>{content}</div>}
    </div>
  );
};

export default Ttip;
