import React from "react";
import styles from "./heroSection.module.css";
import iciciLogo from "../../../../assets/Marketing_landing_Pages/HeroSection/iciciLogo.svg";
import HeroCarousel from "./HeroCarousel/HeroCarousel";

const titles = [
  { id: 1, title: "Be smart & efficient!" },
  { id: 2, title: "Give market-linked returns" },
  { id: 3, title: "Help you save taxes today" },
  { id: 4, title: "Be cost effective" },
];

const HeroSection = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div>
          <button>
            <p>National pension scheme</p>
          </button>

          <p>Your retirement planning should...</p>
          <HeroCarousel items={titles} />
        </div>
        <div>
          <button>
            <span>Open My NPS Account</span>
          </button>

          <div>
            <p>In partnership with</p>
            <img src={iciciLogo} alt="icici logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
